(function () {
  let imageAltText = (function () {
    'use strict';

    tinymce.PluginManager.add('imagealttext', function (editor, url) {
      let openDialog = function () {
        return editor.windowManager.open({
          title: 'Insert Image',
          body: {
            type: 'panel',
            items: [
              {
                type: 'urlinput',
                name: 'url',
              label: 'Source*'
            },
            {
              type: 'input',
              name: 'title',
              label: 'Image Title'
            }, 
            {
              type: 'input',
              name: 'altText',
              label: 'Image Alt Text*'
            }
          ]
          },
          onChange: function(api){
            let data = api.getData(),
                url = data.url.value,
                altText = data.altText.value;

            const element = document.getElementById('error');

            if ( element && url ) {
              element.remove();
            }

            if ( element && altText ) {
              element.remove();
            }
          },
          onSubmit: function (api) {
            let data = api.getData(),
                url = data.url.value,
                title = data.title,
                altText = data.altText;

            let error = document.createElement('div');
            error.style.color = 'red';
            error.style.fontSize = '14px';
            error.id = 'error';

            let labelGroup = document.body.getElementsByClassName('tox-form__group');
            const element = document.getElementById('error');

            if ( url.length > 0 ) {
              if ( altText.length >= 5) {
                editor.insertContent('<img src="' + url + '" alt="' + altText + '" title="' + title + '">');
                api.close();
              } else {
                if ( !element ) {
                  let errorMsg = document.createTextNode('Minimum of 5 characters');
                  error.appendChild(errorMsg);
                  labelGroup[2].appendChild(error);
                }
                return;
              }
            } else {
              if ( !element ) {
                let errorMsg = document.createTextNode('Required');
                error.appendChild(errorMsg);
                labelGroup[0].append(error);
              }
              return;
            }
          },
          buttons: [
            {
              text: 'Cancel',
              type: 'cancel'
            },
            {
              text: 'Save',
              type: 'submit',
              primary: true,
            },
          ]
        });
      }

      editor.ui.registry.addButton('imagealttext', {
        icon: 'image',
        onAction: function () {
          openDialog();
        }
      });
    });
  }());
}());

